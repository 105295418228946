// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutme-js": () => import("../src/pages/aboutme.js" /* webpackChunkName: "component---src-pages-aboutme-js" */),
  "component---src-pages-appointments-js": () => import("../src/pages/appointments.js" /* webpackChunkName: "component---src-pages-appointments-js" */),
  "component---src-pages-components-components-js": () => import("../src/pages/Components/Components.js" /* webpackChunkName: "component---src-pages-components-components-js" */),
  "component---src-pages-components-sections-section-basics-js": () => import("../src/pages/Components/Sections/SectionBasics.js" /* webpackChunkName: "component---src-pages-components-sections-section-basics-js" */),
  "component---src-pages-components-sections-section-carousel-js": () => import("../src/pages/Components/Sections/SectionCarousel.js" /* webpackChunkName: "component---src-pages-components-sections-section-carousel-js" */),
  "component---src-pages-components-sections-section-completed-examples-js": () => import("../src/pages/Components/Sections/SectionCompletedExamples.js" /* webpackChunkName: "component---src-pages-components-sections-section-completed-examples-js" */),
  "component---src-pages-components-sections-section-download-js": () => import("../src/pages/Components/Sections/SectionDownload.js" /* webpackChunkName: "component---src-pages-components-sections-section-download-js" */),
  "component---src-pages-components-sections-section-examples-js": () => import("../src/pages/Components/Sections/SectionExamples.js" /* webpackChunkName: "component---src-pages-components-sections-section-examples-js" */),
  "component---src-pages-components-sections-section-javascript-js": () => import("../src/pages/Components/Sections/SectionJavascript.js" /* webpackChunkName: "component---src-pages-components-sections-section-javascript-js" */),
  "component---src-pages-components-sections-section-login-js": () => import("../src/pages/Components/Sections/SectionLogin.js" /* webpackChunkName: "component---src-pages-components-sections-section-login-js" */),
  "component---src-pages-components-sections-section-navbars-js": () => import("../src/pages/Components/Sections/SectionNavbars.js" /* webpackChunkName: "component---src-pages-components-sections-section-navbars-js" */),
  "component---src-pages-components-sections-section-notifications-js": () => import("../src/pages/Components/Sections/SectionNotifications.js" /* webpackChunkName: "component---src-pages-components-sections-section-notifications-js" */),
  "component---src-pages-components-sections-section-pills-js": () => import("../src/pages/Components/Sections/SectionPills.js" /* webpackChunkName: "component---src-pages-components-sections-section-pills-js" */),
  "component---src-pages-components-sections-section-tabs-js": () => import("../src/pages/Components/Sections/SectionTabs.js" /* webpackChunkName: "component---src-pages-components-sections-section-tabs-js" */),
  "component---src-pages-components-sections-section-typography-js": () => import("../src/pages/Components/Sections/SectionTypography.js" /* webpackChunkName: "component---src-pages-components-sections-section-typography-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-galleries-js": () => import("../src/pages/galleries.js" /* webpackChunkName: "component---src-pages-galleries-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-messagewillnotarrive-js": () => import("../src/pages/messagewillnotarrive.js" /* webpackChunkName: "component---src-pages-messagewillnotarrive-js" */),
  "component---src-pages-myescape-js": () => import("../src/pages/myescape.js" /* webpackChunkName: "component---src-pages-myescape-js" */),
  "component---src-pages-sections-contact-section-js": () => import("../src/pages/Sections/ContactSection.js" /* webpackChunkName: "component---src-pages-sections-contact-section-js" */),
  "component---src-pages-sections-most-recent-books-js": () => import("../src/pages/Sections/MostRecentBooks.js" /* webpackChunkName: "component---src-pages-sections-most-recent-books-js" */),
  "component---src-pages-sections-project-social-section-js": () => import("../src/pages/Sections/ProjectSocialSection.js" /* webpackChunkName: "component---src-pages-sections-project-social-section-js" */),
  "component---src-pages-socialresponsibility-js": () => import("../src/pages/socialresponsibility.js" /* webpackChunkName: "component---src-pages-socialresponsibility-js" */)
}

